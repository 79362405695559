import React, { useState } from 'react'
import { HashLink } from 'react-router-hash-link'
import Icon from '../../ui/Icon/Icon'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'
import './CookieNotification.sass'

function CookieNotification() {
  const [acceptedCookie, setAcceptedCookie] = useState(
    localStorage.getItem('acceptedCookies')
  )

  const onCookiesAccept = () => {
    localStorage.setItem('acceptedCookies', true)
    setAcceptedCookie(true)
  }

  return !acceptedCookie ? (
    <div className="Site-CookieNotification">
      <div className="CookieNotification-Container">
        <div className="CookieNotification-Text">
          Используя веб-сайт iterrainc.ru, вы соглашаетесь с использованием
          файлов cookies, а также{' '}
          <HashLink
            to="/privacy-policy"
            smooth
            scroll={(el) => scrollWithOffset(el, -128)}
          >
            политикой обработки персональных данных
          </HashLink>{' '}
        </div>
        <div className="CookieNotification-Button">
          <Icon name="times" onClick={onCookiesAccept} />
        </div>
      </div>
    </div>
  ) : null
}

export default CookieNotification
