import React, { useContext } from 'react'
import Icon from '../../../ui/Icon/Icon'
import { AuthContext } from '../../../App'
import './PhoneChooser.sass'

function PhoneChooser() {
  return (
    <a href="tel:+79318016611" className="PhoneChooser">
      <Icon name="mobile" weight="regular" className="ChooserIcon" />
      <span className="PhoneChooser-ActivePhone">+7 (931) 801-66-11</span>
    </a>
  )
}

export default PhoneChooser
