import React from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import Carousel from '../../ui/Carousel/Carousel'
import './Numbers.sass'
import Button from '../../ui/Button/Button'
import InfoLine from '../../ui/InfoLine/InfoLine'
import { sdPhotos, soPhotos, stPhotos, buPhotos, kPhotos } from './photos'

function Numbers() {
  const data = [
    {
      id: 'k',
      photos: kPhotos,
      title: `Комфорт`,
      description: `Номер,располагающейся в эркерной части здания, увеличенной площади.Все необходимые удобства в номере: санузел,гигиенические принадлежности,чайная станция и холодильник, фен и смарт-ТВ. Удобный диван для душевных вечерних посиделок в каждом таком номере.Письменный стол придется по душе путешествующим по работе гостям, а семьям с детьми мы с радостью предоставим детскую кроватку с постельным бельем и теплым пледом.`,
    },
    {
      id: 'so',
      photos: soPhotos,
      title: `Стандарт одноместный`,
      description: `Уютный номер для комфортного проживания одного человека.Все необходимые удобства в номере: санузел,гигиенические принадлежности,чайная станция и холодильник, фен и смарт-ТВ. Письменный стол придется по душе путешествующим по работе гостям.`,
    },
    {
      id: 'sd',
      photos: sdPhotos,
      title: `Стандарт двухместный`,
      description: `Просторный номер для двух человек.Все необходимые удобства в номере: санузел,гигиенические принадлежности,чайная станция и холодильник, фен и смарт-ТВ. Письменный стол придется по душе путешествующим по работе гостям, а семьям с детьми мы с радостью предоставим детскую кроватку с постельным бельем и теплым пледом.`,
    },
    {
      id: 'st',
      photos: stPhotos,
      title: `Стандарт трехместный`,
      description: `Удобный номер для трех человек,где всем будет комфортно.Все необходимые удобства в номере: санузел,гигиенические принадлежности,чайная станция и холодильник, фен и смарт-ТВ. Письменный стол придется по душе путешествующим по работе гостям, а семьям с детьми мы с радостью предоставим детскую кроватку с постельным бельем и теплым пледом.`,
    },
    {
      id: 'bu',
      photos: buPhotos,
      title: `Эконом с удобствами на этаже`,
      description: `Просторные номера для двоих гостей без санузла. Мини-холодильник,смарт-ТВ,чайная станция и удобный письменный стол. Санузел и мини-кухня со всем необходимым в шаговой доступности на этаже.`,
    },
  ]
  return (
    <section id="numbers" className="Numbers DefaultSection">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title="Выберите ваш номер" />
            <div className="Numbers-Container">
              {data.map((d, i) => (
                <NumberTile {...d} i={i} />
              ))}
              <div className="ExtraServices">
                <p className="Title">По запросу</p>
                <InfoLine
                  icon="baby"
                  title="Предоставление детской кроватки
                и постельного белья"
                />
                <p className="Title" style={{ marginTop: '24px' }}>
                  Дополнительно
                </p>
                <InfoLine icon="soup" title="Ужины и обеды" />
                <InfoLine icon="washer" title="Стирка белья" />
                <InfoLine icon="luggage-cart" title="Хранение багажа" />
                <InfoLine icon="taxi" title="Трансфер от и до гостиницы" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function Slider({ el, photos }) {
  return (
    <Carousel
      element={`el-${el}`}
      options={{
        type: 'carousel',
        perView: 1,
        autoplay: 5000,
        useDots: true,
        useArrows: false,
      }}
    >
      {photos.map((p) => (
        <img key={p} src={p} data-fancybox="gallery" data-src={p} />
      ))}
    </Carousel>
  )
}

function NumberTile({ i, title, description, id, photos }) {
  return (
    <div className="NumberTile">
      <Slider el={i} photos={photos} />
      <div className="NumberTile-InfoContainer">
        <p className="Title">{title}</p>
        <p className="Description">{description}</p>
      </div>
      <Button
        type="navlink"
        path="/book"
        title={'Забронировать номер'}
        fill="accent"
        theme="solid"
      />
    </div>
  )
}

export default Numbers
