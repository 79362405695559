import React from 'react'
import './Home.sass'
import Hero from '../../components/Hero/Hero'
import ContactUs from '../../components/ContactUs/ContactUs'
import LegacyInfo from '../../components/LegacyInfo/LegacyInfo'
import Numbers from '../../components/Numbers/Numbers'
import Food from '../../components/Food/Food'
import About from '../../components/About/About'

function Home() {
  return (
    <div className="Home">
      <Hero />
      <Numbers />
      <Food />
      <About />
      <ContactUs />
      <LegacyInfo />
    </div>
  )
}

export default Home
