import React from 'react'
import './InfoLine.sass'
import Icon from '../Icon/Icon'

function InfoLine({ icon, title, content }) {
  return (
    <p className="InfoLine">
      {icon && <Icon name={icon} weight="solid" />}
      <span className="Bolder">{title}</span>
      {content}
    </p>
  )
}

export default InfoLine
