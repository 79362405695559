import React from 'react'
import './LegacyInfo.sass'

function LegacyInfo() {
  return (
    <div className="LegacyInfo">
      <div className="container">
        <div className="row">
          <div className="col-12 LegacyInfo-Container">
            <p>
              Юридический адрес: Общество с ограниченной ответственностью
              «Северный город» ИНН 5190171917/КПП 519001001 183032, г. Мурманск
              ул. Полярные Зори, дом 4 офис 205 В . ОКПО 81082770, ОГРН
              1075190019057
            </p>
            <a href="/documents/dog.pdf" target="blank">
              Договор публичной оферты
            </a>{' '}
            <a href="/documents/policy.pdf" target="blank">
              Политика обработки персональных данных и конфиденциальности в
              отношении обработки персональных данных
            </a>{' '}
            <a href="/documents/sogl.pdf" target="blank">
              Согласие на обработку персональных данных
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LegacyInfo
