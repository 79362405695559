import bu1 from './assets/numbers/bu/1-min.jpg'
import bu2 from './assets/numbers/bu/2-min.jpg'
import bu3 from './assets/numbers/bu/3-min.jpg'
import bu4 from './assets/numbers/bu/4-min.jpg'

import k1 from './assets/numbers/k/1-min.jpg'
import k2 from './assets/numbers/k/2-min.jpg'
import k3 from './assets/numbers/k/3-min.jpg'
import k4 from './assets/numbers/k/4-min.jpg'
import k5 from './assets/numbers/k/5-min.jpg'
import k6 from './assets/numbers/k/6-min.jpg'

import sd1 from './assets/numbers/sd/1-min.jpg'
import sd2 from './assets/numbers/sd/2-min.jpg'
import sd3 from './assets/numbers/sd/3-min.jpg'
import sd4 from './assets/numbers/sd/4-min.jpg'

import so1 from './assets/numbers/so/1-min.jpg'
import so2 from './assets/numbers/so/2-min.jpg'
import so3 from './assets/numbers/so/3-min.jpg'
import so4 from './assets/numbers/so/4-min.jpg'

import st1 from './assets/numbers/st/1-min.jpg'
import st2 from './assets/numbers/st/2-min.jpg'
import st3 from './assets/numbers/st/3-min.jpg'
import st4 from './assets/numbers/st/4-min.jpg'
import st5 from './assets/numbers/st/5-min.jpg'

export const buPhotos = [bu1, bu2, bu3, bu4]
export const kPhotos = [k1, k2, k3, k4, k5, k6]
export const sdPhotos = [sd1, sd2, sd3, sd4]
export const soPhotos = [so1, so2, so3, so4]
export const stPhotos = [st1, st2, st3, st4, st5]
