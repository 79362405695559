import React from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './Food.sass'
import foodImg from './food.jpg'

function Food() {
  return (
    <section className="Food DefaultSection">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title="Завтрак" />
            <div className="Food-Container">
              <div className="Info">
                <p>
                  В нашем отеле Вы можете начать свой день с аромата наших
                  вкусных и сытных завтраков.С любовь приготовим для Вас
                </p>
                <ul>
                  <li>Венские вафли</li>
                  <li>Овсяную/рисовую кашу на молоке</li>
                  <li>Вареные яйца и сосиски </li>
                  <li>Сырно-колбасную нарезку</li>
                  <li>Овощную нарезку</li>
                  <li>Фрукты(по сезону)</li>
                  <li>Ароматный хлеб</li>
                  <li>Сливочное масло,мед,джем</li>
                  <li>Сухие завтраки</li>
                  <li>Чай и заварной кофе</li>
                  <li>Кисломолочную продукцию(сырки/йогурты)</li>
                </ul>
              </div>
              <div className="Food-Img">
                <img src={foodImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Food
