import React, { useContext } from 'react'
import './About.sass'
import { AuthContext } from '../../App'
import Button from '../../ui/Button/Button'
import hero from './about.jpg'

function About() {
  return (
    <section
      id="about"
      className="About DefaultSection"
      style={{ backgroundImage: `url(${hero})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <AboutContent
              description={
                <>
                  <p>
                    Современный отель на 25 номеров открывает свои двери для
                    гостей региона и жителей нашей области!
                  </p>
                  <p>
                    В распоряжении гостей 17 номеров с удобствами, среди
                    которых,конечно же, мы не забыли выделить номера для семей с
                    детьми и четвероногими друзьями, а так же, 8 номеров с
                    удобствами на этаже, но таких же комфортных и уютных. От
                    обувной ложки до чайной чашки, от фена до холодильника - все
                    самое необходимое Вы найдёте в каждом номере и все это Ваше
                    на весь срок проживания в АйТерра Хотел!{' '}
                  </p>
                  <p>
                    {' '}
                    Просторная столовая на первом этаже и уютная кухня на
                    третьем оснащены кухонной утварью и посудой для любителей
                    готовить даже на отдыхе, а для тех, кто во время поездки не
                    привык тратить силы у плиты, мы приготовим вкусный и сытный
                    завтрак, а так же позаботимся об обеде и ужине - стоит
                    только захотеть ;)
                  </p>
                  А ещё к Вашим услугам:
                  <br />
                  - камера хранения багажа
                  <br />
                  - трансфер <br />
                  - предоставление детских принадлежностей <br />
                  - предоставление принадлежностей для питомцев <br />
                  - сувенирная продукция <br />
                  - стирка белья <br />- предоставление утюга и гладильной доски
                  по требованию <br />
                  - экскурсионное обслуживание <br />
                  - бесплатная охраняемая парковка
                  <br />
                </>
              }
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export const AboutContent = ({ title = 'hvoya', description }) => {
  const { setShowContactUs } = useContext(AuthContext)

  return (
    <div className="About-Container">
      <div className="About-Info">
        <div className="Title">iterra hotel</div>
        <div className="Description">{description}</div>
        {/* <div className="Buttons">
          <Button
            title="Заказать обратный звонок"
            fill="accent"
            theme="solid"
            onClick={() =>
              setShowContactUs({
                show: true,
                title: 'Заказать обратный звонок',
                emailSubject: 'Заказ обратного звонка',
              })
            }
          />
        </div> */}
      </div>
      {/* <div className="Avatar"><Img src={aboutimg} /></div> */}
    </div>
  )
}

export default About
