import React from 'react'
import './Hero.sass'
import { withRouter } from 'react-router-dom'
import parse from 'html-react-parser'
import { NavHashLink } from 'react-router-hash-link'
import Icon from '../../ui/Icon/Icon'
import hero1 from './1.jpg'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'
import Button from '../../ui/Button/Button'
import location from '../../locale/location'
import { useContext } from 'react'
import { AuthContext } from '../../App'

function Hero() {
  // const { lang } = useContext(AuthContext)
  // const t = title ? title : parse(location[lang].hero.title)

  return (
    <div id="home" className="Site-Hero">
      <div className="SiteHero-Container">
        <div
          className="Offer-Container"
          style={{ backgroundImage: `url(${hero1})` }}
        >
          <div className="Offer">
            <div className="Offer-Content">
              <h1 className="Offer-Title">iterra hotel - уютная гостиница</h1>
              <p className="Offer-Description">В центре Кольского Севера</p>
              <div className="Offer-Buttons">
                <NavHashLink
                  to={''}
                  smooth
                  scroll={(el) => scrollWithOffset(el, -64)}
                >
                  <Button
                    title={'Забронировать номер'}
                    fill="accent"
                    theme="solid"
                    type="navlink"
                    path="/book"
                  />
                </NavHashLink>
              </div>
            </div>
          </div>
        </div>
        <div className="Offer-SocialBlock">
          <a href="https://vk.com/iterra_hotel" target="blank">
            <Icon name="vk" weight="brand" />
          </a>
          <a href="" target="blank">
            <Icon name="telegram" weight="brand" />
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=79318016611
          "
            target="blank"
          >
            <Icon name="whatsapp" weight="brand" />
          </a>
          <a href="viber://chat?number=79318016611" target="blank">
            <Icon name="viber" weight="brand" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Hero)
